import { Container, Row, Col } from "react-bootstrap";
import "animate.css";
import Animation from "./Animation";

export const Banner = () => {
  return (
    <>
      <section className="banner" id="home">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={6} xl={7}>
              <div>
                <h1 className="animate__animated animate__fadeInDown">
                  {`Hey! I'm Prathyusha. I build User Interfaces`}
                </h1>
                <p className="animate__animated animate__fadeInLeft animate__delay-1s">
                  I am a frontend developer with a passion for building dynamic,
                  user-centered web applications. With over 7 years of
                  experience, I specialize in crafting seamless, responsive UIs
                  using HTML5, CSS3, JavaScript, and modern frameworks like
                  React, Angular, and Vue. My goal is to create intuitive
                  experiences that are both functional and visually appealing. I
                  have deep experience with styling frameworks such as Tailwind
                  CSS, Bootstrap, and Material UI, and I focus on optimizing
                  performance and accessibility across various platforms and
                  devices. Whether it's managing state with Redux or building
                  complex component structures, I’m well-versed in the tools
                  needed to deliver high-quality applications. Collaboration is
                  key, and I regularly work with designers using tools like
                  Figma and InVision to ensure the final product matches the
                  vision. I’m always committed to learning new technologies and
                  staying ahead of the curve, ensuring that my work is always
                  modern, efficient, and user-focused. Let’s bring your ideas to
                  life and build something impactful together!
                </p>
              </div>
            </Col>
            <Col xs={12} md={6} xl={5}>
              <Animation />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
